.root {
  display: grid;
  grid-template-columns: min-content fit-content(80%) min-content;
  background-color: #444444;
  height: 100vh;
  max-width: 100%;
  justify-content: space-between;
}

.hidden {
  display: none;
}

.not_hidden {
  display: block;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  width: 100%;
}

.logo {
  margin-bottom: 50px;
}

.controls {
  display: flex;
  gap: 8px;
}

.leftSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 278px;
}

.rightSidebar {
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.fieldButton {
  backdrop-filter: blur(150px);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.fieldButtonActive {
  cursor: pointer;
  background: var(--gray);
}

.slide {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.slide img {
  max-width: 100%;
}
.dropdown {
  margin-bottom: 48px;
}

.heading {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--dark-gray);
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .closeButton {
    width: 16px;
    height: 16px;
    padding: 0;
    border: none;
    background: none;
  }
}
