.root {
  display: flex;
  flex-direction: column;

  button {
    text-align: left;
    margin-bottom: 10px;
  }
}

.subheading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #111111;
  margin-top: 24px;
  margin-bottom: 8px;
}
