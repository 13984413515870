.root {
  display: grid;
  grid-template-columns: 1fr 480px;
  height: 100%;
  position: relative;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coreInner {
  /* display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; */
  /* position: relative; */
}

.buttonsFullscreen {
  position: relative;
  z-index: 450;
}

.sidebar {
  width: 100%;
}

.buttons {
  width: 100%;
}

.svgButtonFullscreen {
  display: none;
}

.coreMiddle {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
}

.coreWrapperFullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 300;
}

.svgContainerFullscreen {
  position: absolute;
  margin-bottom: 0;
  top: 50%;
  left: 50%;
  max-width: inherit;
  max-height: inherit;
}

.svgContainer {
  padding: 0;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  background: #fafafa;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  line-height: 0;
}

.imageValue {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.closeFullscreen {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  right: 48px;
  top: 24px;
  width: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
  border: 0;
  z-index: 10000;
  cursor: pointer;
}

.coreZoom {
  user-select: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 12px;
  left: 24px;
  top: 120px;
  z-index: 100;
  width: 24px * 2;
}

.svgButtonFullscreen {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: #ddd;
  cursor: pointer;
}

.svgButtonFullscreen:hover {
  color: #fafafa;
}

.svgButtonFullscreen svg {
  width: 24px;
  height: 24px;
  display: block;
}

.svgArea {
  top: 50%;
  left: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.current .rect {
  cursor: move;
}

.rectCircleDelete {
  cursor: pointer;
  fill: transparent;
}

.coreScroller {
  margin-bottom: 0;
}
