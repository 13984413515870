.menu {
  background-color: var(--black);
  color: var(--white);
}

.option {
  padding-left: 24px;
  display: flex;
  gap: 10px;
}

.checkmark {
  position: absolute;
  left: 10px;
}
