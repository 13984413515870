.date {
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  position: absolute;
  top: 24px;
  left: 24px;
  text-align: left;
  font-weight: 300;
}

.name {
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  font-feature-settings: 'liga' off;
  color: var(--black);
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  max-width: 100%;
}

.dots {
  position: absolute;
  right: 24px;
  top: 24px;
}

.menuPopup {
  background: var(--black);
  border-radius: 20px;
  padding: 8px;
  display: flex;
  gap: 8px;
}
