.root {
  background-color: var(--light-black);
}

.root .container {
  padding-top: 104px;
  padding-bottom: 120px;
}

.root .card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
}

.heading {
  margin-bottom: 48px;
  color: var(--white);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 275px);
  grid-auto-rows: 240px;
  gap: 32px;
  overflow-y: auto;
}

@media screen and (max-width: 1250px) {
  .grid {
    grid-template-columns: repeat(auto-fit, 220px);
  }
}

@media screen and (max-width: 790px) {
  .grid {
    grid-template-columns: repeat(auto-fit, 250px);
  }
}

@media screen and (max-width: 590px) {
  .grid {
    grid-template-columns: repeat(auto-fit, 320px);
  }
}