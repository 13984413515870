.root {
  background-color: var(--white);
  padding-top: 96px;
  padding-bottom: 96px;
}

.back {
  margin-bottom: 18px;
}

.cell {
  display: flex;
  align-items: center;
  gap: 16px;
}

.addDocument {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.card {
  min-height: 200px;
}

.createBuild {
  background: #dddddd;
  border-radius: 16px;
  padding: 16px 24px;
  min-height: 160px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buildDocumentsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.buildDocumentsListRow {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 12px;
  gap: 8px;
  align-items: center;
}

.deleteDocument {
  cursor: pointer;
}

.plusButton {
  background: #fafafa;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 24px;
}
.plusButton:hover {
}

.createBuildButton {
  margin-top: 24px;
}

.buildNameInput input {
  padding-left: 0;
  font-size: 24px;
  line-height: 32px;
}

.builds {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.model {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.heading {
  margin-bottom: 48px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 32px;
  padding-bottom: 72px;
}

.input {
  margin-bottom: 72px;
  width: 100%;
}

.input input {
  color: var(--black);
  width: 100%;
}

.input input:not(:focus) {
  cursor: pointer;
  color: var(--black);
}

.input input:focus {
  color: var(--dark-gray);
}

.emptyClassifier {
  border: 2px dashed var(--dark-gray);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px 24px;
  color: var(--dark-gray);
}

.gridHeading {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 28px;
}

.documents {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ocr {
  display: grid;
  align-items: center;
  gap: 8px;
  width: 100%;
  grid-template-columns: 36px 1fr;
}
