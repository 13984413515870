.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  border-radius: 16px;
  background-color: var(--light-gray);
  position: relative;
}

.date {
  font-size: 12px;
  line-height: 16px;
  color: #111111;
  margin-bottom: 8px;
  font-weight: normal;
}

.name {
  font-size: 24px;
  line-height: 32px;
  color: #111111;
}

.text {
  gap: 4px;
}

.stage {
  text-align: right;
}

.stage button {
  width: 100%;
}

.successButton {
  width: 100%;
  border: 2px solid #32bc42;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: 48px;
  /* identical to box height, or 125% */

  color: #32bc42;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.successButtonIcon {
  position: absolute;
  left: 16px;
}

.remove {
  position: absolute;
  right: 16px;
  top: 16px;
}

.removeBase {
  padding: 8px;
  border-radius: 50%;
}

.buildError {
  color: var(--red);
  border: 2px solid var(--red);
  box-sizing: border-box;
  border-radius: 24px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  padding: 0 16px;
}
