.root {
  padding-top: 144px;
  padding-bottom: 144px;
}

.close {
  right: 24px;
  top: 24px;
  position: absolute;
}
