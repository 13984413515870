.buildDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #111111;
  margin-bottom: 8px;
}

.buildHeader {
  display: flex;
  justify-content: space-between;
}
.buildRemove {
  cursor: pointer;
}

.buildName, .buildNameInput input {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #111111;
  margin-bottom: 48px;
  user-select: none;
  padding: 0;
}

.buildError {
  color: var(--red);
  border: 2px solid var(--red);
  box-sizing: border-box;
  border-radius: 24px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  padding: 0 16px;
}

.button {
  margin-top: 15px;
}

.buttonRun, .button {
  width: 100%;
}

.buttons {
  display: flex;
  gap: 8px;
}
