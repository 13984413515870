.dialogActions {
  padding-bottom: 16px;
}

.dialog {
  background-color: var(--white);
}

.dialog .title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: var(--black);
  font-family: inherit;
}

.dialog .text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  font-family: inherit;
  color: #000000;
  padding-bottom: 0;
}

.dialog .content {
  padding-bottom: 40px;
}

.dialog .dialogActions {
  padding-top: 0;
}
