.scroller {
  overflow: hidden;
  position: relative;
  background-color: black;
}

.scrollerDisabled {
  overflow: auto;
}

.scrollerGrabbed {
  user-select: none;
}

.scrollerMiddle {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.scrollerInner {
  overflow: hidden;
}

.scrollerInnerDisabled {
  overflow: auto;
}

.scrollerScroll {
  position: absolute;
  right: 6px;
  bottom: 6px;
}

.scrollerScrollHorizontal {
  left: 6px;
}

.scrollerScrollVertical {
  top: 6px;
}
.scrollerDrag {
  cursor: pointer;
  background: #444444;
  position: absolute;
  border-radius: 10000px;
  z-index: 1;
}
.scrollerDragHorizontal {
  height: 100%;
}

.scrollerDragVertical {
  width: 100%;
}
