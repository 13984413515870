.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.imageBox {
  height: 232px;
  width: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}

.img {
  max-width: 100%;
  max-height: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.imgContainer {
  width: 100%;
  height: 232px;
  padding: 16px;
  background-color: var(--light-gray);
  text-align: center;
  position: relative;
}

.imgContainer:hover {
  background-color: var(--gray);
  cursor: pointer;
}

.name {
  font-weight: normal;
}
