.layout {
    overflow: hidden;
}

.button {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 5;
    cursor: pointer;
    user-select: none;
    background-color: gray;
    border: 2px solid transparent;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
}

.container {
    display: flex;
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 48px);
    padding: 100px 0;
}

.item {
    border: 2px solid gray;
    border-radius: 10px;
}

.item:first-child {
    overflow-y: auto;
}

.item:first-child,
.item:last-child {
    padding: 20px;
    text-align: center;
    flex: 1 0 30%;
}

.item:nth-child(2) {
    flex: 1 0 60%;
    margin: 0 25px;
    position: relative;
}

.item:nth-child(2) .block {
    cursor: default;
    position: absolute;
}

.item:last-child {
    flex: 1 0 20%;
}

.title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
}

.block {
    text-align: center;
    user-select: none;
    width: 100%;
    font-size: 20px;
    padding: 15px 5px;
    border: 1px solid #000000;
    background-color: rgb(255, 173, 33);
    cursor: grab;
}

.block:not(:last-child) {
    margin-bottom: 25px;
}

.form button[type="submit"] {
    display: none;
}

.formHidden {
    display: none;
}