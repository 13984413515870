.root {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #111111;
  margin-bottom: 12px;
  margin-top: 8px;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
  position: relative;
}

.block {
  background: #dddddd;
  border-radius: 8px;
  padding: 16px 16px 16px 16px;
  margin-left: 0;
}

.delete {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.row {
  flex-direction: row;
  align-items: center;
}

.checkbox {
  margin-right: 8px;
}

.checked:not(.empty) .checkbox {
  margin-bottom: 8px;
}
