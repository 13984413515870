.react-flow__attribution {
  display: none;
}

.react-flow__node {
  padding: 15px 20px;
  border: 1px solid #000;
}

.react-flow__node .react-flow__handle {
  width: 8px;
  height: 8px;
}

.validationflow .react-flow__node {
  width: 150px;
  border-radius: 5px;
  padding: 10px;
  color: #555;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 12px;
}

.validationflow .react-flow__node-customnode {
  background: #e6e6e9;
  border: 1px solid #ddd;
}

.react-flow__node.selected {
  border: 1px solid blue;
}

.react-flow__node-custominput .react-flow__handle {
  background: #e6e6e9;
}

.validationflow .react-flow__node-custominput {
  background: #fff;

}

.validationflow .react-flow__handle-connecting {
  background: #ff6060;
}

.validationflow .react-flow__handle-valid {
  background: #55dd99;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'TT Norms Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --accent-color: var(--blue);
  --light-gray: #eeeeee;
  --dark-gray: #aaaaaa;
  --gray: #dddddd;
  --disabled: #aaaaaa;
  --dark-blue: #002a5b;
  --blue: #1356a8;
  --green: #32bc42 !important;
  --red: #c60c31;
  --orange: #f99018;
  --black: #111111;
  --light-black: #444444;
  --white: #fafafa;
  color: var(--black);
  background-color: var(--white);
  font-feature-settings: 'liga' off;
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('./fonts/TTNormsPro/subset-TTNormsPro-Regular.woff2') format('woff2'),
    url('./fonts/TTNormsPro/subset-TTNormsPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('./fonts/TTNormsPro/subset-TTNormsPro-Medium.woff2') format('woff2'),
    url('./fonts/TTNormsPro/subset-TTNormsPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.n-resize {
  cursor: n-resize;
}

.w-resize {
  cursor: w-resize;
}

.e-resize {
  cursor: e-resize;
}

.s-resize {
  cursor: s-resize;
}

.ne-resize {
  cursor: ne-resize;
}

.nw-resize {
  cursor: nw-resize;
}

.se-resize {
  cursor: se-resize;
}

.sw-resize {
  cursor: sw-resize;
}

.transparent {
  fill: transparent;
}

.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  border-radius: 20px !important;
}