.root {
  background-color: var(--light-gray);
  padding-top: 72px;
  overflow-y: scroll;
}

.title {
  font-size: 32px;
  line-height: 40px;
  color: var(--black);
  font-weight: 500;
}

.sub {
  margin-bottom: 16px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-auto-rows: auto;
  gap: 32px;
}

.name {
  margin-bottom: 72px;
  width: 100%;
}

.name input {
  color: var(--black);
  width: 100%;
}

.input {
  margin-bottom: 72px;
  width: 100%;
}
.input input {
  width: 100%;
}

.input.error input {
  color: var(--red);
}

.pipeline {
  position: relative;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 24px;
}

.row {
  position: relative;
  text-align: center;
}

.lastRow {
  left: 12px;
}

.disabled {
  color: var(--dark-gray);
}

.progress {
  height: 9px;
  background-color: var(--gray);
  top: 65px;
  width: calc(100% + 24px);
  display: block;
  position: absolute;
  left: 0;
  transform: translateX(50%);
  z-index: 0;
}

.progress.success {
  background-color: #32bc42;
}
.row > .bigButton {
  width: 100%;
  height: 96px;
  border-radius: 120px;
  font-size: 32px;
  line-height: 40px;
}

.uploading {
  background: var(--black);
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 190px;
}

.uploading .progress {
  background-color: #32bc42;
  height: 100%;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(0);
  transition: 0.3s ease all;
  z-index: 1;
}

.uploading .label {
  position: relative;
  z-index: 2;
  color: var(--white);

  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.datasetPreview {
  background: #fafafa;
  border: 4px solid #32bc42;
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  position: relative;
  max-height: 200px;
  min-height: 200px;
  display: flex;
  justify-content: center;
}

.datasets {
  display: flex;
  flex-direction: column;
}

.datasetPreview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.datasetCount {
  background: var(--gray);
  border-radius: 16px;
  padding: 8px 16px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-size: 14px;
  line-height: 16px;
}

.circle {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray);
  color: var(--dark-gray);
  border-radius: 50%;
  z-index: 1;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
}

.circle.green {
  background-color: var(--green);
  color: var(--white);
}

.circle.black {
  background-color: var(--black);
  color: var(--white);
}

.annotationButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dropzone {
  padding-bottom: 32px;
}

.circle.orange {
  background-color: transparent;
  color: var(--white);
  position: relative;
  top: -4px;
}

.fieldnetError {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 10px;
}

@media screen and (max-width: 1440px) {
  .bigButton {
    width: 100%;
    height: 68px;
    border-radius: 60px;
    font-size: 24px;
    line-height: 32px;
  }
}
