.root {
}

.heading {
  margin-bottom: 48px;
}

.popup {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  z-index: 1000;
  top: 0;
}

.back {
  margin-bottom: 22px;
}
