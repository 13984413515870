.input_text {
    color: #555;
    border: 1px solid #ddd;
    width: 100%;
    outline: 1px solid #ddd;
}

.animated {
    stroke-dasharray: 5;
    animation: dashDraw .5s linear infinite;
}

@keyframes dashDraw {
    0% {
        stroke-dashoffset: 10;
    }
}