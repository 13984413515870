.root {
  position: relative;
  box-sizing: border-box;
  padding-top: 48px;
  min-height: 100%;
}

.header {
  /* height: 48px; */
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

@media screen and (max-width: 600px) {
  .root {
    /* min-height: 100%; */
    /* min-height: none; */
    display: block;
  }
}
