.root {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  background: var(--black);
  color: var(--white);
  width: 48px;
  height: 120px;
  justify-content: space-between;
  border-radius: 48px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.plus,
.minus {
  cursor: pointer;
}
