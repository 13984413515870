.root {
  font-family: 'TT Norms Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 64px;
}

.mini {
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #111111;
}

.sub {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--dark-gray);
}

.back {
  margin-bottom: 20px;
}
@media screen and (max-width: 600px) {
}
