.root {
  display: flex;
  width: 320px;
  flex-direction: column;
  gap: 24px;
}

.button {
  margin-top: 16px;
  max-width: 96px;
}
