.root {
  width: 100%;
  height: 48px;
  position: relative;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  overflow: hidden;
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #32bc42;
  z-index: 1;
}

.label {
  position: relative;
  z-index: 2;
  color: var(--gray);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.root.small {
  height: 48px;
  font-size: 24px;
}

.small .label {
  font-size: 18px;
  line-height: 1.2;
}

@media screen and (max-width: 1440px) {
}
