.root {
  background: var(--white);
  border-radius: 16px;
  padding: 24px;
  display: inline-block;
  transition: 0.3s ease all;
  cursor: pointer;
  width: 100%;
}

.root:hover {
  background-color: var(--light-gray);
}

.blue {
  box-shadow: 0px 0px 24px 0px rgba(19, 86, 168, 1);
}

.red {
  box-shadow: 0px 0px 24px 0px rgba(198, 12, 49, 1);
}

.green {
  box-shadow: 0px 0px 24px 0px rgba(50, 188, 66, 1);
}

.simple {
  background-color: var(--white);
}

.backgroundBlue {
  background-color: var(--blue);
}
.backgroundBlue:hover {
  background-color: var(--dark-blue);
}

.disabled {
  border: 2px dashed #aaaaaa;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: var(--dark-gray);
}

.notClickable {
  cursor: default;
}
