.markup {
  width: 74px;
  height: 48px;
  border-radius: 24px;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.markup_sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  overflow-y: auto;
  max-height: 600px;
  padding: 10px;
}

.container::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10em;
}

.container::-webkit-scrollbar {
  width: 5px;
}

.field {
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 74px 1fr 12px;
  gap: 16px;
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.submit {
  margin-top: 56px;
}

.fullscreen {
  z-index: 0;
  position: relative;
}
.fullscreen .heading {
  color: var(--white);
}

.heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  color: var(--black);
}
