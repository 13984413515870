.root {
  background-color: var(--white);
  min-height: 100%;
}
.root .plus {
  /* width: 320px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.root .card {
  min-height: 220px;
}

.heading {
  margin-bottom: 48px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-auto-rows: auto;
  gap: 32px;
}

.name {
  margin-bottom: 72px;
  width: 100%;
}

.name input {
  color: var(--black);
  width: 100%;
}
