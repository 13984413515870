body {
  --accent-color: var(--blue);
  --light-gray: #eeeeee;
  --dark-gray: #aaaaaa;
  --gray: #dddddd;
  --disabled: #aaaaaa;
  --dark-blue: #002a5b;
  --blue: #1356a8;
  --green: #2ac178;
  --red: #c60c31;
  --dark-red: #b6000d;
  --black: #111111;
  --light-black: #444444;
  --white: #fafafa;
  color: var(--black);
  background-color: var(--white);
}

._RpsiW {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  color: var(--black);
  background: var(--white);
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  outline: none;
  font-family: inherit;
  border: 2px solid transparent;
}

._TSHkR {
  border-radius: 12px;
}

._KvTNt {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._3uA6t {
  font-size: 16px;
  font-weight: 500;
}

._TSHkR._3uA6t {
  border-radius: 24px;
  padding: 14px 24px;
}

._KvTNt._3uA6t {
  width: 60px;
  height: 60px;
}

._1Ovlj {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

._TSHkR._1Ovlj {
  border-radius: 16px;
  padding: 9px 24px;
}

._KvTNt._1Ovlj {
  width: 50px;
  height: 50px;
}

._3oLgK {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

._KvTNt._1Ovlj {
  width: 40px;
  height: 40px;
}

._TSHkR._3oLgK {
  border-radius: 14px;
  padding: 4px 10px;
}

._2NMAm {
  background: var(--white);
  color: var(--black);
}

._1Ewuq {
  background: var(--blue);
  color: var(--white);
}

._3Niou {
  background: var(--red);
  color: var(--white);
}

._3IM54 {
  background: var(--gray);
  color: var(--black);
}

._3Zs8D {
  background: transparent;
  color: var(--blue);
  border-color: var(--blue);
  border-width: 2px;
  border-style: solid;
}

._3Zs8D:hover {
  color: var(--dark-blue);
  border-color: var(--dark-blue);
}

._1Ewuq:hover {
  background: var(--dark-blue);
}

._3Niou:hover {
  background: var(--dark-red);
}

._2NMAm:hover {
  background: var(--gray);
}

._3sG3Y:hover {
  background: var(--white);
  color: var(--black);
}

._2zEaz {
  background-color: #e08b09;
  color: var(--white);
}

._2zEaz:hover {
  background-color: #c07608;
  color: var(--white);
}

._3sG3Y {
  background: var(--black);
  color: var(--white);
}

._KqAO0:hover {
  background: var(--light-black);
  color: var(--white);
}

._KqAO0 {
  background: var(--black);
  color: var(--white);
}

._1Ewuq {
  background: var(--blue);
}

._3HYm4 {
  background: #32bc42;
  color: var(--white);
}

._3HYm4:hover {
  background: #258d31;
  color: var(--white);
}

._1Ewuq:hover {
  background: var(--dark-blue);
}

._-5sms {
  background: var(--disabled);
  border-color: var(--disabled);
  color: var(--white);
  cursor: default;
}

._-5sms:hover {
  background: var(--disabled);
  border-color: var(--disabled);
  color: var(--white);
}

._2NZ9O,
._Jkm39 {
  border: none;
  background: transparent;
  padding: 0;
  font-weight: 500;
  color: var(--blue);
}

._28jO9 {
  border: none;
  background: transparent;
}

._2NZ9O {
  color: var(--blue);
  border-bottom: 1px dotted var(--blue);
  border-radius: 0;
  line-height: 1;
}

._TSHkR._2NZ9O {
  border-radius: 0;
  padding: 0;
  line-height: 1.6;
}

._2NZ9O:hover {
  color: var(--dark-blue);
  border-color: var(--dark-blue);
}

._Jkm39 {
  color: var(--white);
}

._1g6QO {
  color: transparent !important;
}

._1g6QO._3sG3Y:hover {
  background: var(--black) !important;
  color: var(--white);
}

._3lc2r {
  width: 4px;
  height: 4px;
  display: block;
  background-color: #fafafa;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  /* opacity: 0; */
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: _24BX_;
          animation-name: _24BX_;
}

._3lc2r:nth-child(1) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

._3lc2r:nth-child(2) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

._3lc2r:nth-child(3) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

._3lc2r:nth-child(4) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

._1rMsS {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

._1rMsS._2aSkg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

@-webkit-keyframes _24BX_ {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes _24BX_ {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

._3FrXj {
  color: var(--black);
}

._3MgaV {
  border-radius: 6px;
  background: var(--light-gray) !important;
  border-radius: 6px;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  width: 100%;
  outline: none;
  border: 2px solid transparent;
  box-sizing: border-box;
  font-feature-settings: 'liga' off;
}

._3MgaV::-moz-placeholder {
  color: #aaa;
}

._3MgaV:-ms-input-placeholder {
  color: #aaa;
}

._3MgaV::placeholder {
  color: #aaa;
}

._3MgaV:focus {
  border: 2px solid var(--blue);
  width: 100%;
}

._-Rq9o:focus {
  border: 2px solid var(--black);
}

._1q3lK {
  font-style: normal;
  color: var(--black);
  position: relative;
}

._3KS9n {
  background: var(--light-gray);
  outline: none;
  border: 2px solid transparent;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 500;
}

._2j9gE {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  border-radius: 6px;
  width: 100%;
}

._ih57q ._3KS9n {
  background: transparent;
  border: none;
}

._ih57q ._3KS9n:focus {
  border: none;
}

._2Qidy {
  font-size: 14px;
  line-height: 18px;
  padding: 6px 8px;
  border-radius: 4px;
  width: 100%;
}

._2KN1c {
  font-size: 56px;
  line-height: 64px;
  font-weight: 500;
}

._3KS9n::-moz-placeholder {
  color: #aaa;
}

._3KS9n:-ms-input-placeholder {
  color: #aaa;
}

._3KS9n::placeholder {
  color: #aaa;
}

._1Y0RB ._3KS9n:focus {
  border: 2px solid var(--black);
}

._3KS9n:focus {
  border: 2px solid var(--blue);
}

._1RQab ._3KS9n {
  border-color: var(--red);
}

._LZXs8 input {
  background: var(--white) !important;
}

._2stBj {
  font-size: 14px;
  color: var(--red);
  font-weight: normal;
  margin-top: 8px;
  position: absolute;
  bottom: -1.57em;
}

._16O9o input {
  width: 100%;
  max-width: 40px;
  padding: 4px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

._16O9o {
  display: inline;
}

._MV15o svg {
  height: 20px;
  width: 20px;
}

._MV15o {
  position: absolute;
  right: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  opacity: 0.3;
  transition: 0.3s ease all;
}

._1FPb9 {
  padding-right: calc(12px + 20px + 4px);
}

._MV15o:hover {
  opacity: 0.8;
}

._3mOXt {
  border: 2px solid;
  height: 12px;
  width: 12px;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

._3pzSG {
  position: relative;
  top: 6px;
}

._rIrlt {
  width: 4px;
  height: 4px;
  display: block;
}

._2JxJA {
  border-color: #aaa;
}

._2JxJA ._rIrlt {
  background-color: #aaa;
}

._3bc3Y {
  border-color: #fafafa;
}
._3bc3Y ._rIrlt {
  background-color: #fafafa;
}

._3n85U {
  width: 24px;
  height: 24px;
  background-color: var(--light-gray);
  border: none;
  border-radius: 4px;
  flex-shrink: 0;
}

._2Cwqm {
  line-height: 24px;
}

._3n85U._3pzSG {
  position: static;
}

._vwwxk {
  border-color: var(--black);
}

._vwwxk ._rIrlt {
  background-color: var(--black);
}

._3kdrc {
  cursor: pointer;
}

._2E4nV {
  align-items: center;
}

._1LyBL {
  display: flex;
  gap: 8px;
}

._mBjET {
  font-family: 'TT Norms';
  font-weight: 500;
  line-height: 1.2;
}

._3Ko9t {
  text-align: center;
}

._1PSPT {
  font-size: 72px;
  margin-bottom: 36px;
}

._1Xp9g {
  font-size: 48px;
  margin-bottom: 60px;
}

._16UsP {
  font-size: 32px;
  margin-bottom: 20px;
}

._19kgC {
  font-size: 24px;
  margin-bottom: 12px;
}

._2vsPB {
  font-size: 20px;
  margin-bottom: 12px;
}

._3Qnqt {
  font-size: 16px;
  margin-bottom: 8px;
}

@media screen and (max-width: 600px) {
  ._1PSPT {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  ._1Xp9g {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  ._16UsP {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  ._1QApU {
    margin-bottom: 0;
  }
}

._R_6c8 {
  font-style: normal;
  font-weight: normal;
  font-feature-settings: 'liga' off;
}

._3KO36 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

._1kbNE {
  font-size: 24px;
  line-height: 36px;
}

._3Ehi9 {
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: var(--blue);
}

@media screen and (max-width: 600px) {
  ._1kbNE {
    font-size: 20px;
    line-height: 1.6;
  }
}

._21RHc {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  min-width: 32px;
}

/* Hide default HTML checkbox */
._21RHc input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
._U3CHN {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  border-radius: 16px;
  transition: 0.4s;
}

._U3CHN:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 0;
  bottom: 0;
  background-color: #aaaaaa;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + ._U3CHN:before {
  background-color: #1356a8;
}

input:checked + ._U3CHN:before {
  transform: translateX(100%);
}

/* Rounded sliders */
._U3CHN._3kZJ1 {
  border-radius: 34px;
}

._U3CHN._3kZJ1:before {
  border-radius: 50%;
}

@import '~react-datepicker/dist/react-datepicker.css';

._1hiiH {
  display: grid;
  grid-template-columns: 8px 8px 8px;
  gap: 4px;
  cursor: pointer;
  position: relative;
  padding: 10px;
  /* display: inline-block; */
}

._1hiiH:hover ._2zfl8 {
  background: #444444;
}

._2zfl8 {
  width: 8px;
  height: 8px;
  background: #aaaaaa;
  border-radius: 50%;
}

._1mWV2 {
  background: #111111;
  padding: 16px 24px;
  position: absolute;
  /* top: -50px; */
  left: 0;
  transform: translateX(-50%);
  border-radius: 12px;
  z-index: 30;
}

._3BZlO {
  background: var(--black) !important;
  border-radius: 4px;
  color: var(--white) !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 10px 16px !important;
}

._jvjis {
  color: var(--black) !important;
  fill: var(--black) !important;
}

._2mmoP {
  background: #aaaaaa;
  border-radius: 8px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._2mmoP:hover {
  background-color: var(--black);
}

._16aZE {
  background: #dddddd;
  border-radius: 12px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  position: relative;
}

._19J4k {
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #111111;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

._1iMXB {
  transform: translateX(100%);
  background: #aaaaaa;
  border-radius: 12px;
  cursor: default;
  display: block;
  height: 100%;
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: 0.3s ease all;
}

._3vfl5 {
  color: #fafafa;
}

._2ZJTu ._1iMXB {
  transform: translateX(0);
}

._3vfl5 svg path {
  fill: #fafafa;
}

._1n4aw {
  position: relative;
  width: 48px;
  height: 48px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  display: block;
  margin-left: 12px;
  margin-right: -12px;
}

._1FHXv {
  display: block;
  background: var(--black);
  width: 24px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}

._1JUVO ._1FHXv {
  background: var(--white);
}

._1FHXv:first-child {
  transform: translate(-50%, 4px);
}

._1FHXv:last-child {
  transform: translate(-50%, -4px);
}

._1n4aw:not(:hover),
._1n4aw:not(:hover) ._1FHXv {
  -webkit-animation-duration: 0s !important;
          animation-duration: 0s !important;
}

._nInxt ._1FHXv:first-child {
  -webkit-animation: _23-ll 0.1s linear forwards;
          animation: _23-ll 0.1s linear forwards;
}
._nInxt ._1FHXv:last-child {
  -webkit-animation: _Uztkn 0.1s linear forwards;
          animation: _Uztkn 0.1s linear forwards;
}

/* .disabled .hamburger-line:first-child {
  animation: topLineOff 0.1s linear forwards;
}

.disabled .hamburger-line:last-child {
  animation: topLineOff 0.1s linear forwards;
} */

@-webkit-keyframes _23-ll {
  0% {
    transform: translate(-50%, -4px);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 0) rotate(45deg);
  }
}

@keyframes _23-ll {
  0% {
    transform: translate(-50%, -4px);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 0) rotate(45deg);
  }
}

@-webkit-keyframes _Uztkn {
  0% {
    transform: translate(-50%, 4px);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 0) rotate(-45deg);
  }
}

@keyframes _Uztkn {
  0% {
    transform: translate(-50%, 4px);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 0) rotate(-45deg);
  }
}

@-webkit-keyframes _1Q3Wp {
  0% {
    transform: translate(-50%, 0) rotate(45deg);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, -4px);
  }
}

@keyframes _1Q3Wp {
  0% {
    transform: translate(-50%, 0) rotate(45deg);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, -4px);
  }
}

@-webkit-keyframes _2wvci {
  0% {
    transform: translate(-50%, 0) rotate(-45deg);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 4px);
  }
}

@keyframes _2wvci {
  0% {
    transform: translate(-50%, 0) rotate(-45deg);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 4px);
  }
}

._16rCd {
  display: flex;
  gap: 12px;
  position: relative;
}

._3vA_K,
._TmPFx {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  color: var(--black);
  cursor: pointer;
}

._3vA_K:hover {
  background-color: var(--gray);
}

._3vA_K._1Bu0R {
  color: var(--white);
  background-color: var(--black);
  cursor: default;
}

._35Lnc._QbFJG {
  transform: translateX(calc(100% + 12px)) rotate(-180deg);
  right: 0;
  left: auto;
}

._35Lnc {
  position: absolute;
  left: 0;
  transform: translateX(calc(-100% - 12px));
  border: 2px solid #111111;
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  cursor: pointer;
  height: 100%;
}

._35Lnc:hover {
  background-color: var(--gray);
}
