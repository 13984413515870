.root {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--light-black);
}

.close {
  right: 24px;
  top: 24px;
  position: absolute;
  z-index: 10;
}
