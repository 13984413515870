.root {
  background-color: var(--white);
  padding-bottom: 24px;
  padding-top: 64px;
  display: flex;
}

.container {
  background-color: var(--light-gray);
  padding-top: 72px;
  margin: 0 24px;
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  width: 100%;
}

.controls {
  right: 24px;
  top: 24px;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 48px;
}
