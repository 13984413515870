.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--blue);
  position: relative;
  background-image: url('./Login.svg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.logo {
  position: absolute;
  left: 24px;
  top: 24px;
}
