.root {
  background-color: transparent;
  text-align: left;
  align-items: center;
  height: 48px;
  display: flex;
  padding: 0 24px;
  position: relative;
  overflow-y: hidden;
}

.root.dark {
  background-color: var(--black);
}

.link {
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-gray);
  text-decoration: none;
  font-weight: 500;
  margin-right: 20px;
}

.logo {
  cursor: pointer;
}

.root .logout {
  padding: 4px 12px;
  justify-self: flex-start;
}

.plus {
  background-color: var(--blue);
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
}

.nav {
  display: flex;
  margin-left: 40px;
  margin-right: auto;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .content {
    display: flex;
    justify-content: space-between;
  }
}
