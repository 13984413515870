.root {
  max-width: 740px;
  width: 100%;
}

.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 12px;
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.dropzone {
  border: 4px dashed #aaaaaa;
  box-sizing: border-box;
  border-radius: 16px;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  color: #aaaaaa;
  margin-bottom: 24px;
  position: relative;
  transition-property: border-color, color;
  transition: 0.5s ease;
}

.dropzone:hover {
  border-color: var(--blue);
  color: var(--blue);
}

.dropzone:hover svg path {
  fill: var(--blue);
}

.dropzone svg path {
  transition: 0.5s ease;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.fileListHeader {
  text-align: left;
  margin-bottom: 28px;
  padding: 0 24px;
  font-weight: 500;
}

.name {
  /* max-width: 100%; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fileList {
  text-align: center;
}

.classifyButton {
  margin-top: 16px;
  text-align: left;
  padding-left: 24px;
  display: flex;
  align-items: center;
}

.fileListHeader,
.fileListItem {
  display: grid;
  grid-template-columns: 60% 30% 10%;
  gap: 24px;
}

.fileListItem {
  margin-bottom: 8px;
  text-align: left;
  height: 48px;
  padding: 0 24px;
}

.rejected {
  color: #c60c31;
}

.sizeExceededHint {
  font-size: 12px;
  lighting-color: 16px;
  margin-left: 24px;
  color: #c60c31;
}

.fileListItem > div {
  display: flex;
  align-items: center;
}

.fileListItem:hover {
  background: #eeeeee;
}

.deleteFile {
  cursor: pointer;
  /* padding: 5px; */
  line-height: 1;
  position: relative;
  top: -2px;
  font-size: 32px;
  display: block;
  margin-left: auto;
}

.deleteIcon {
  background: #dddddd;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
}

.deleteIcon:hover {
  background: #aaaaaa;
}

@media screen and (max-width: 600px) {
  .dropzone {
    border-width: 4px;
  }
  .classifyButton {
    display: grid;
    gap: 20px;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .title {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
  }

  .subtitle {
    display: none;
  }

  .dropzone {
    height: 144px;
    border-radius: 156px;
  }
}
